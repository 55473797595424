<br />
<br />


  <div class="sub">
    <div class="middleSubA">
      <div>
        <div class="videoLeft">
          <div style="margin-left: 10px; color: white">
            <h1 style="color: white; margin-top: 10px">
              Accordant<br />
              works for you.
            </h1>
            <br />
            Your personalized care team, a dedicated<br />
            nurse and resource specialists, backed by<br />
            our Medical Advisory Board, provide<br />
            proactive support.<br /><br />
            Access our total approach to your health and wellness:<br />
            <ul style="text-align: left; margin-right: 20px">
              <li>Better understand your health</li>
              <li>Proactively address risks before they affect your health</li>
              <li>Find support and assistance within your local community</li>
            </ul>
            See what it means to have support from<br />
            Accordant.
          </div>
        </div>
        <div class="videoRight">
          <iframe
            src="https://player.vimeo.com/video/896317707?badge=0"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
    <div class="middleSubB">
      <div style="text-align: left; margin-top: 10px; font-size: 20px">
        "The support that Accordant provides for both<br/>
         physicians and patients is invaluable. The extra <br/> support patients receive through Accordant<br/>
         reinforces the treatment program outlined by the<br/> multi-disciplinary cystic fibrosis team and provides reassurance for the patient and the physician."<br/>
        <div style="font-size: 15px">
          Lynne Quittell, MD <br/><br/>
          Director of the Cystic Fibrosis Center and Pediatric Lung Transplant Center, Babies and Children’s <br/>
          Hospital, Columbia University’s College of Physicians and Surgeons
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <div class="subBottom">
    <div class="resourcesTitle">
      Accordant Care Resources
    </div>
    <!--  <span class="frequentTitle">Accordant Care Resources</span>-->
    <div class="bottomSubA">
      <div>
        <a href="https://www.accordant.com/" target="_blank">
          <img  src="assets/images/img.png" style="height: 50%; width: 50%; margin-top: 100px"/>
          <br/>
        </a>
        <br/>
        <br/>
        <div>
          <span style="font-size: 15px">Accordant Care website</span>
        </div>

      </div>
      <br />
      <br />
      <br />
    </div>
    <div class="bottomSubB">
      <a href="https://extranet.accordant.com/index.php" target="_blank">
        <img src="assets/images/Extranet.png"  style="height: 40%; width: 40%; margin-top: 50px;"/>
      </a>
      <br/>
      <br/>
      <div>
        <span style="font-size: 15px">Extranet site</span>
      </div>
    </div>
    <div class="bottomSubC">
      <a href="https://itsm.cvs.com/now/nav/ui/classic/params/target/home.do" target="_blank">
        <img src="assets/images/ServiceNow.jpg"  style="height: 40%; width: 40%; margin-top: 50px;"/>
      </a>
      <br/>
      <br/>
      <div>
        <span style="font-size: 15px">Service Now</span>
      </div>
    </div>
  </div>

