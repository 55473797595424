<!--suppress HtmlUnknownBooleanAttribute -->
<cvs-loader-overlay-container [isLoading]="isLoading">
  <div class="dialog-header">
    <h1 class="title">Feedback Form</h1>
    <button class="cvs-icon-only-btn" matDialogClose>
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </div>
  <p>All fields are required.</p>
  <p>If feedback is specific to a dashboard. Please mention dashboard/view name.</p>
  <dialog-alert-placeholder></dialog-alert-placeholder>
  <form [formGroup]="feedbackForm">
    <div>
      <mat-form-field appearance="outline" class="feedbackTextArea">
        <textarea rows="5" matInput placeholder="Type your feedback here" formControlName="feedbackText"
                  [maxlength]="maxLength"></textarea>
        <mat-hint>{{this.feedbackForm.controls.feedbackText.value.length}}/{{maxLength}}</mat-hint>
        <mat-error>This is a required field</mat-error>
      </mat-form-field>

    </div>
    <div class="action-buttons">
      <button class="cvs-btn-ghost" matDialogClose>Cancel</button>
      <button class="cvs-btn-primary" (click)="saveFeedBack()">Submit</button>
    </div>
  </form>
</cvs-loader-overlay-container>
