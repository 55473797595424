import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from "./home-page/home-page.component";
import {DashboardPageComponent} from "./dashboard-page/dashboard-page.component";
import {InitialPageComponent} from "./initial-page/initial-page.component";

const routes: Routes = [
  {path: 'home', component: HomePageComponent},
  {path: 'dashboard/:domain/:id/:name', component: DashboardPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, initialNavigation: 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
