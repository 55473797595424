<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>Initial Page</title>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  </head>
  <body>
    <div id="comp-render" *ngIf="canRenderAppComponent()">
      <app-root [email]="login_email"></app-root>
    </div>
    <div id="display" *ngIf="canRenderInitialPageComponent()">
      <div id="wrapper">
        <div id="leftElement">
          <div id="welcome">
            <p>Welcome to the ACR Reporting Platform</p>
            <svg width="450" height="420">
              <image
                href="assets/images/Pictogram.svg"
                width="420"
                height="400"
              ></image>
            </svg>
            <br />
          </div>
        </div>
        <div id="rightElement">
          <div id="loginStyle">
            <br />
            <img src="assets/images/Logo.svg" />
            <br />
            <br />
            <br />
            <label class="label" for="email">Enter your Email:</label>
            <br />
            <input
              class="emailBox"
              id="email"
              [(ngModel)]="login_email"
              type="email"
              placeholder="Email Address"
              (keydown.enter)="storeUserDomain()"
              (keyup)="validateEmail($event)"
            />
            <br />
            <div id="errorMsg" *ngIf="!isEmailValid">Invalid Email Address</div>
            <br />
            <div id="buttonArea">
              <button (click)="storeUserDomain()" class="smallButton">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>

<!--<second-page></second-page>-->
