import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  AuthCodePKCEService,
  AuthorizationService,
  CVSAlertService,
  CVSAlertType,
  CVSAuthConfig,
  CVSBaseConfig,
  MenuState,
  NavItemConfig,
  SideNavConfig,
  SubNavItem
} from "angular-component-library";
import {Router} from "@angular/router";
import {TableauService} from "./services/tableau.service";
import {mergeMap} from "rxjs/operators";
import {FeedbackDialogComponent} from "./feedback-dialog/feedback-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import Utils from "./shared/utility/Utils";
import {EnvConfig} from "../config/env-config";
import {EnvironmentLoaderService} from "../config/environment-loader.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  @Input()
  public email: string | undefined;
  baseConfig: CVSBaseConfig = { unbranded: true } as CVSBaseConfig;
  authConfig: CVSAuthConfig = { authenticationEnabled:true } as CVSAuthConfig;
  sideNavConfig!: SideNavConfig;
  protected readonly envConfig: EnvConfig;

  constructor(public router: Router, private authorizationService: AuthorizationService,
              private authCodePKCEService: AuthCodePKCEService,private tableauService: TableauService,
              private cvsAlertService: CVSAlertService, private matDialog: MatDialog,
              public translateService: TranslateService,
              private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
    const originalURL = window.location.href;
    let productName = this.envConfig.defaultProduct;
    const map = new Map(Object.entries(this.envConfig.authConfig));
    map.forEach((value, key) => {
      if(originalURL.startsWith(value.matcher)){
        productName = key;
      }
    })

    translateService.setDefaultLang(productName);
    translateService.use(productName);
    this.translateService.get(this.translateService.currentLang).toPromise().then();
    let values = this.envConfig.authConfig
    const userEmail = this.email! || sessionStorage.getItem('login_email')!
    const domain = Utils.findUserDomain(userEmail)
    console.log('userEmail: '+ userEmail+ ' And domain: '+ domain)

    // handle forgot password scenario gracefully
    let params = new URL(window.location.href).searchParams;
    let errorDescription = params.get("error_description");

    if(domain === 'molina'){
      const search = 'B2C_1A_SIGNUP_SIGNIN';
      const replace = 'B2C_1A_SIGNUP_SIGNIN-MOLINA'
      values.tokenUrl = values.tokenUrl.replace(search, replace);
      values.loginUrl = values.loginUrl.replace(search, replace);
      values.logoutUrl = values.logoutUrl.replace(search, replace);
      values.tableauBaseUrl = values.tableauBaseUrl.replace('tableauSiteId','AccordantCareProjectMHCSSO')
    }else if(domain === 'cvs'){
      const search = 'B2C_1A_SIGNUP_SIGNIN';
      const replace = 'B2C_1A_SIGNUP_SIGNIN-CVS'
      values.tokenUrl = values.tokenUrl.replace(search, replace);
      values.loginUrl = values.loginUrl.replace(search, replace);
      values.logoutUrl = values.logoutUrl.replace(search, replace);
      values.tableauBaseUrl = values.tableauBaseUrl.replace('tableauSiteId','AccordantCareproject')
    }else if(domain === 'external' && !errorDescription){
      values.loginUrl = values.loginUrl.concat('?login_hint='+userEmail)
      values.tableauBaseUrl = values.tableauBaseUrl.replace('tableauSiteId','AccordantCareProjectExternal')
    }else if(domain == 'external' && errorDescription! && errorDescription.includes('AADB2C90118: The user has forgotten their password')){
      const search = 'B2C_1A_SIGNUP_SIGNIN';
      const replace = 'B2C_1A_PasswordReset';
      values.tokenUrl = values.tokenUrl.replace(search, replace);
      values.loginUrl = values.loginUrl.concat('?login_hint='+userEmail)
      values.loginUrl = values.loginUrl.replace(search, replace);
      values.logoutUrl = values.logoutUrl.replace(search, replace);
      values.tableauBaseUrl = values.tableauBaseUrl.replace('tableauSiteId','AccordantCareProjectExternal')


    }

    this.authConfig = {
        ...values,
        useAccessToken: false
      } as CVSAuthConfig;


      this.baseConfig = {
        homeRoute: `/home`,
        appName: this.translateService.instant('Header'),
        unbranded: !values!.brandingEnabled
      } as CVSBaseConfig;

    console.log('final token url: '+ values.tokenUrl)

    this.sideNavConfig = {
      sideNavItems: [],
      iconMenu: true,
      menuState: MenuState.Open
    } as SideNavConfig;

    this.authorizationService.getAuthorizedUser().subscribe(user =>{
      this.tableauService.getProjects(user.email,domain).subscribe(projects => {
        const navItemConfig = projects.map(project => {
          return {
            name: Utils.formatLongName(project.name),
            subNavItems : Utils.sortItems(project.workbooks.map(workbook => {
              return {
                name: Utils.formatLongName(workbook.name),
                routerLink: `dashboard/${domain}/${workbook.id}/${workbook.name}`
              } as SubNavItem;
            }))
          } as NavItemConfig;
        });
        this.sideNavConfig.sideNavItems = [{name: 'Home', routerLink: '/home'}, ...Utils.sortItems(navItemConfig)];
        this.baseConfig = {
          homeRoute: '/home',
          appName: this.translateService.instant('Header'),
          unbranded: !values!.brandingEnabled
        } as CVSBaseConfig;
      })
    })
  }

  addFeedback() {
    this.matDialog.open(FeedbackDialogComponent, {
      width: '30%',
      height: 'auto'
    }).afterClosed().subscribe(response => {
      if (response) {
        this.cvsAlertService.sendAlert({
          headline: 'Success',
          body: 'Your feedback has been submitted.',
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          fadeClass: true
        });
      }
    })
  }

}
