import {Injectable} from '@angular/core';
import {CVSHttpClient} from "angular-component-library";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Project} from "../models/project";
import {View} from "../models/View";
import {FrequentView} from "../models/frequent-view";
import {EnvConfig} from "../../config/env-config";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";

@Injectable({
  providedIn: 'root'
})
export class TableauService {

  protected readonly envConfig: EnvConfig;
  protected readonly backendBaseUrl: string;

  constructor(private cvsHttpClient: CVSHttpClient, private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
    this.backendBaseUrl = this.envConfig.backendBaseUrl;
  }


  getProjects(email: string, domain: string): Observable<Project[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/${domain}/projects/${email}`)
        .pipe(map(data => data.projects));
  }

  getView(workbookId: string, domain: string): Observable<View> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/${domain}/view/${workbookId}`)
  }

  getFrequentImg(email: string, domain: string): Observable<FrequentView[]> {
    return this.cvsHttpClient.get(`${this.backendBaseUrl}/${domain}/view/frequentlyViewed/${email}`)
        .pipe(map(data => data.dashboards));
  }

}
