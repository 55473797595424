import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  styleUrls: ['./pages-layout.component.scss']
})
export class PagesLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
