import {NavItemConfig} from "angular-component-library";

export default class Utils {

  static sortItems(data: NavItemConfig[]): NavItemConfig[] {
    return data.sort((a,b) => {
      if (a.name! > b.name!) {
        return 1;
      }
      if (a.name! < b.name!) {
        return -1;
      }
      return 0;
    });
  }

  static findUserDomain(userEmail: string): string{
    const email = userEmail.toLowerCase()
    if(email.includes('molinahealthcare')){
      return 'molina'
    }else if(email.toLowerCase().includes('cvshealth') || email.toLowerCase().includes('accordant') || email.toLowerCase().includes('omnicare') || email.toLowerCase().includes('activehealth')){
      return 'cvs'
    }
    return 'external'
  }
  static formatLongName(name: string): string {
    let formattedNameString = name;
    const formattedName = [...name];
    if(formattedName.length >= 24){
      for (let i = 24; i >= 0; i--) {
        // Covering an edge case where space is right at the margin cutoff and no transformation is needed.
        if(formattedName[i] === ' '){
          break;
        }
        if(formattedName[i] === '_'){
          formattedNameString = name.substring(0, i) + ' - ' + Utils.formatLongName(name.substring(i));
          break;
        }
      }
    }
    return formattedNameString;
  }

}
